<template>
  <div id="histogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Histogram',
  props: {
    incomeInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return { coordinate: null }
  },
  computed: {},

  watch: {
    incomeInfo: {
      deep: true,
      immediate: true,
      handler: function (obj) {
        if (obj) {
          this.init(obj)
        } else {
          this.init()
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目收入统计
    init(obj = {}) {
      const nameArr = obj.nameArr || []
      this.$nextTick(() => {
        var chartDom = document.getElementById('histogramId')
        var myChart = echarts.init(chartDom)
        var option
        option = {
          tooltip: {
            trigger: 'item', // 确保触发方式是单个项
            formatter: function (params) {
              return `
                  类型: ${params.name}<br/>
                  ${params.seriesName}<br/>
                  金额: ${Number(params.value).toLocaleString()}
              `
            },
            // trigger: 'axis', // 触发方式为 axis，这样可以显示所有数据
            // axisPointer: {
            //   type: 'shadow', // 显示阴影指示器
            // },
            // formatter: function (params) {
            //   let result = `${params[0].name}<br/>` // 当前类目名称
            //   params.forEach(item => {
            //     const isHighlighted =
            //       item.seriesIndex === option.tooltip.highlightIndex &&
            //       item.dataIndex === option.tooltip.highlightDataIndex
            //         ? '（当前选中）'
            //         : ''
            //     result += `${item.marker} ${
            //       item.seriesName
            //     }: ${item.value.toLocaleString()}${isHighlighted}<br/>`
            //   })
            //   return result
            // },
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: nameArr,
            },
          ],
          yAxis: [
            {
              type: 'log',
              min: 100000, // 设置y轴最小值
              interval: 10000000,
              max: 100000000,
              show: false,
            },
          ],
          series: [
            {
              name: obj?.data[0]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              // stack: 'a',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[0]?.data,
            },
            {
              name: obj?.data[1]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              // stack: 'a',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[1]?.data,
            },
            {
              name: obj?.data[2]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              stack: 'b',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[2]?.data,
            },
            {
              name: obj?.data[3]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              stack: 'b',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[3]?.data,
            },
            {
              name: obj?.data[4]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔

              stack: 'c',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[4]?.data,
            },
            {
              name: obj?.data[5]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              stack: 'c',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[5]?.data,
            },
            {
              name: obj?.data[6]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              stack: 'b',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[6]?.data,
            },
            {
              name: obj?.data[7]?.name,
              type: 'bar',
              barGap: '0%', // 中间不间隔
              stack: 'c',
              emphasis: {
                focus: 'series',
              },
              data: obj?.data[7]?.data,
            },
          ],
          dataZoom: {
            type: 'slider', // 滑动条类型
            show: nameArr?.length > 10 ? true : false, // 是否显示滑动条,在这里可以根据自己的需求做判断，超过时显示进度条
            startValue: 0, // 展示区域内容的起始数值
            endValue: 14, // 展示区域内容的结束数值
            height: 10, // 滑动条组件高度
            bottom: 5, // 距离图表区域下边的距离
            showDetail: false, // 拖拽时是否显示详情
            showDataShadow: false, // 是否在组件中显示数据阴影
            fillerColor: '#c1c1c1', // 平移条的填充颜色
            borderColor: 'transparent', // 边框颜色
            zoomLock: true, // 锁定视图
            brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
            // 通过该属性可以只滑动，不显示缩放功能
            handleStyle: {
              // 手柄样式
              opacity: 0,
            },
          },
        }
        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
